export const environment = {
  auth0:{
    domain: 'otrleasing.us.auth0.com',
    clientId: 'dXOkc1BL9K6n8bYfqvH3PeWzaGgfRUHI',
    redirect_uri: 'http://localhost:4200/login'
  },
  applynow:{
    base_uri: 'https://dev.api.apply.otrleasing.com/api/'
  },
  google: {
    gtm_code: 'GTM-58SSPJWH'
  },
  lease_path: {
    base_uri: 'https://api.otrleasing.com/api/',
    key: 'c8RyTGVzaq5nU2VydmljZVVzZXI6VyEzM1NlcnZpY2GAcHE='
  },
  otrleasing: {
    base_uri: 'https://otrleasing.com/',
  },
  otrleasing_apply: {
    base_uri: 'https://dev.apply.otrleasing.com/',
  }
};
